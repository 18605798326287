.mobile-card-service-container{
    display: none;
}

.service-card{
    height: auto;
    max-width: 90%;
    box-shadow:  0.1rem .1rem 0rem rgb(138, 132, 132);
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
    padding: 10px;
    border-top: none;
}
.service-card-title{
    padding-top: 8px;
    font-size: 17px;
    color: rgb(12, 6, 6);
}

.service-card-content-container{
    color: rgb(78, 77, 77);
    text-align: center;
}

.service-card-description{
    font-size: 14px;
    color: rgb(100, 100, 100);
}

.service-card-list{
    color: rgb(48, 47, 47);
    width: auto;
    max-width: 14rem;
    padding: 3px;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24);
    background-image:linear-gradient(to bottom right, rgb(255, 248, 234), rgb(255, 236, 184), rgb(253, 226, 152));
    border-radius: 15px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.service-card-img{
    height: 100px;
    width: 100px;
    background-repeat: no-repeat;
    margin-left: auto;
    margin-right: auto;
}

@media only screen and (max-width: 780px) {
    .service-img-content{
        display: none;
    }
    .mobile-card-service-container{
        display: block;
    }
}