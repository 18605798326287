/* Root Colors */
:root{
    --orange: rgb(255, 123, 0);
    --white: #ffffff;
    --grey: #333333;
    --black: #000000;
}

/* full container styles */

.full-container{
    font-family: "Inter-Medium";
    position: relative;
}

.section-title{
    width:25vw;
    min-width: 20vw;
    font-size: 22px;
    position: relative;
    background-color: transparent;
    margin: 1.2vw auto;
    padding: 1.2vw;
    color: rgb(39, 38, 38);
    font-weight: 700;
}

.section-title:before {
    content: "";
    position: absolute;
    bottom: 0;
    width: 40%;
    left: 30%;
    margin-right: -25%;
    border-bottom: solid var(--orange) .3rem;
  }

u, li{
    list-style: none;
}


/* Navigation-Bar Styles */
.custom-nav{
    width:100%;
    max-height: 134px;
    position: fixed;
    z-index: 1000;
    background-color: var(--white);

}
.menu-nav-items{
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;
}

.nav-logo{
    height: 7rem;
    width: 7rem;
}

.nav-item{
    text-decoration-line: none;
    text-decoration: none;
    background-color: white;
    padding: 2vw;
    color: var(--grey);
    font-weight: 700;
    font-size: 18px;
    font-family: "Bold";
    margin-left: 1.4vw;
}

.nav-item:hover{
    color: var(--orange);
}

.email{
    background-color:var(--orange);
    color: var(--grey);
    opacity: .9;
    border-radius: 5px;
    border: none;
    padding: 4px;
    font-size: 18px;
    font-family: "Bold";
    margin-left: 1.4vw;
}

.email:hover{
    opacity: 0.7;
    color: rgb(112, 2, 2);
}

.toggler:focus{
    box-shadow: none;
    color: white;
    background-color: #000000;
}

.navbar {
    margin-bottom: 0;
    border-radius: 0;
}

.toggle-icon{
    margin-top: 2rem;
    background-color:transparent;
    border: none;
    border-radius: 5px;
}

.icon{
    color: var(--orange);
}
.nav-space{
    width: 30%;
}


/* Corousel styles */
.corousel-content-full{
   height:auto;
   width: 100%;
   margin-top: 100px;
}


/* Our-Services Styles */
.service-img-title{
    position: absolute;
    top: 0%;
    left: 0%;
    border-radius: 0px 0px 5px 0px;
    color:  var(--white);
    font-size: 20px;
    font-weight: 700;
    border-left: solid var(--orange) .6rem;
    z-index: 900;
    padding: .8vw;
}
.service-img:hover{
    opacity: 0.9;
}

.service-img-card{
    position: relative;
    height: 340px;
    width: 100%;
    box-sizing: border-box;
    box-shadow: solid grey 1rem;
    border: none;
}

.service-img-1{
    background-image: url('../resources/system_service.jpg');
    position: relative;
    height: 340px;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

}
.service-img-2{
    background-image: url('../resources/artwork_service.jpg');
    position: relative;
    height: 340px;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}
.service-img-3{
    background-image: url('../resources/troubleshoot_service.jpg');
    position: relative;
    height: 340px;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.service-content-card{
    position: relative ;
    height: auto;
    margin: 5px;
    min-height: 20vw;
    border-radius: 0px 0px 6px 6px;
    width: 100%;
    box-sizing: border-box;
    margin-right: auto;
    margin-left: auto;
    background-image: linear-gradient(to bottom right, rgb(32, 32, 32), rgb(124, 124, 124));
    box-shadow: solid rgb(39, 38, 38) 1rem;
    border: none; 
}
.card-content{
    top: 15px;
    display: flex;
    flex-wrap: wrap;
}

.service-content-left-title{
    font-size: 18px;
    color: var(--white);
}
.service-content-left-content{
    color: #B3B3B3;
    text-align: left;
    font-size: 17px;
}


.service-content-left{
    width: 60%;
    text-align: left;
}
.service-content-right{
    width: 40%;
    position: relative;
    vertical-align: middle;
}

.service-content-right:before{
    content: "";
    position: absolute;
    left: 0;
    top: 8%;
    height: 80%;
    width: 10%;
    border-left: 2px solid var(--orange);
}

.btn-on-img{
    position: absolute;
    height: auto;
    width: auto;
    top: 75%;
    left: 80%;
    padding: 6px;
    border-radius: .8vw;
    background-image:linear-gradient(to bottom right, rgb(255, 129, 11), rgb(255, 230, 0));
    color: var(--white);
    font-size: 18px;
    text-align: center;
    border: none;
}

.btn-on-img:hover{
    box-shadow: 0 2px 7px 0 rgb(248, 203, 2);
}



/* About-Us Styles */
.about-content{
    width: 100%;
    height: auto;
}
.about-section-content{
    max-width: 80%;
}

/* Contact-Us Styles */
.contact{
    background-color: var(--grey);
    width: 100%;
    height: auto;
}
.contact-title {
    text-align: right;
    padding: 1rem 2rem 2rem 0rem;
    color: var(--white);
    font-size: 16px;
    font-weight: 600;
}
.contact-data {
    text-align: left;
    padding: 1rem 0rem 2rem 2rem;
    color: var(--white);
}
.contact-table{
    margin-left: auto;
    margin-right: auto;
}


/* Footer Styles */
.footer-logo{
    height: 150px;
    width: 150px;
    margin: 8vw 1vw 0vw 0vw;
}
.footer-title{
    font-weight: 500;
    font-size: 17px;
    padding: 0px;
    
}
.footer-heading{
    font-weight: 600;
    font-size: 1.4rem;
    padding-top: 1.4rem;
}
.footer-service{
    padding: 20px;
}
footer {
    background-color: var(--white);
    height: 20vw;
}
.copywright{
    font-size: 15px;
}


/* Modal Styles */

.modal{
    height: auto;
    width: auto;
    min-width: 50%;   
    position: relative;
}

.modal-btn{
    color: black;
    border-color:var(--orange) ;
}

.close{
    background-color: transparent;
}
.close:hover{
    background-color: rgb(204, 79, 79);
    border: none;
}

.send{
    background-color: var(--orange);
    font-weight: 400;
}
.send:hover{
    background-color: var(--orange);
    opacity: 0.6;
    border: none;
}
.send:active{
    background-color: var(--orange);
}
.modal-alert{
    position: absolute;
}



@media only screen and (max-width: 780px) {
    .contact-title{
        padding-left: 5px;
        text-align: right;
     }
    .contact-data{
        padding-right: 5px;
        text-align: left;
    }
    .menu-list{
        display: none;
    }

    .logo-section{
        display: none;
    }

    .contact-title, .contact-data{
        padding: 1rem;
    }

    li{
        font-size: 14px;
    }

    .footer-title{
        font-size: 17px;
    }
    .footer-heading{
        font-size: 18px;
    }
    .footer-service{
        padding: 2px;
    }
    .footer-logo{
       display: none;
    }
    .footer-service{
        display: flex;
        flex-wrap: wrap;
    }
    .copywright{
        font-size: 14px;
    }
    .nav-logo{
        height: 5rem;
        width: 5rem;
    }
}

@media only screen and (max-width: 980px) {
    .footer-logo{
        height: 130px;
        width: 130px;
        margin-top: 8rem;
    }

}

@media only screen and (max-width: 640px) {
    .contact-title {
        font-size: 12px;
    }
    .footer-title{
        font-size: 13px;
    }
    .footer-heading{
        font-size: 15px;
    }
    li{
        font-size: 11px;
    }
    .copywright{
        font-size: 12px;
    }
    .nav-logo{
        height: 5rem;
        width: 5rem;
    }
    
}

@media only screen and (max-width: 570px) {
    .modal{
        width: 100%;
    }
    .nav-space{
        width: 14%;
    }
    .corousel-content-full{
        height:230px;
     }
}

@media only screen and (max-width: 400px) {
    .copywright{
        font-size: 11px;
    }
    .nav-logo{
        height: 4rem;
        width: 4rem;
    }
    .menu-nav-items{
        box-sizing: border-box;
        justify-content: space-between;
    }
    .nav-space{
        width: 12%;
    }

}

@media only screen and (max-width: 340px) {
    .nav-space{
        display: none;
    }
    .email{
        padding: 2px;
        font-size: 14px;
        margin-left: 1vw;
    }
    .corousel-content-full{
        height:180px;
     }
}





 