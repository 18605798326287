::-webkit-scrollbar {
    width: 0px;
  }
  
  .lg-carousel{
    display: block;
  }

  .carousel-container{
    display: none;
    overflow-x: auto;
    width: 100%;
    padding: 20px 0;
  }
  
  .carousel-card {
    flex-shrink:0;
    width:95%;
    height: 450px;
    border-radius: 10px;
    margin-left: 10px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
  
  .carousel-title{
     color: white;
     font-weight: 700;
     background-color: rgba(255, 174, 0, 0.664);
     padding: 5px;
     border-radius: 20px;
     font-size: 22px;
  }

@keyframes swipe {
  0% {
    transform: translate(0);
  }

  100% {
    transform: translate(-100%);
  }
}


  @media only screen and (max-width: 570px) {
    .lg-carousel{
      display: none;
    }
    .carousel-card {
      flex-shrink:0;
      width:95%;
      height: 200px;
    }
    .carousel-container{
      display: flex;
    }
}

@media only screen and (max-width: 340px) {
  .carousel-card {
    flex-shrink:0;
    width:95%;
    height: 150px;
  }
}

@media only screen and (max-width: 800px) {
  .carousel-title{
    font-size: 16px;
 }

}


