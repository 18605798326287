body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Bold";   
  src: local("Oswald-Bold"),
    url("./fonts/static/Oswald-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Medium";
  src: local("Oswald-Medium"),
    url("./fonts/static/Oswald-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Inter-Bold";   
  src: local("Inter-Bold"),
    url("./fonts/static/Inter-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Inter-Medium";
  src: local("Inter-Medium"),
    url("./fonts/static/Inter-Medium.ttf") format("truetype");
}


